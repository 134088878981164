import './App.css';
import {
  Box,
  Center,
  ChakraProvider,
  Flex,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import AzureOpenAI from './components/chat/bots/AzureOpenAI';
import VoiceChatWindow from './components/voice-chat/VoiceChatWindow';
import { useState, useEffect } from 'react';
import OpenAI from './components/chat/bots/OpenAI';

function App() {
  const isIOS = useMediaQuery('(pointer: fine)');
  const {
    isOpen: isAzureOpenAiVoiceChatOpen,
    onClose: onAzureOpenAiVoiceChatClose,
    onOpen: onAzureOpenAiVoiceChatOpen,
  } = useDisclosure();

  const {
    isOpen: isOpenAiVoiceChatOpen,
    onClose: onOpenAiVoiceVoiceChatClose,
    onOpen: onOpenAiVoiceVoiceChatOpen,
  } = useDisclosure();

  const [conversationId, setConversationId] = useState<string | null>(null);

  const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  useEffect(() => {
    if (!conversationId) {
      setConversationId(generateGUID());
    }
  }, [conversationId]);

  return (
    <ChakraProvider>
      <Center h={{ base: isIOS ? 'auto' : 'auto', md: '95vh' }}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          w={{ base: 'full', md: 'auto' }}
          gap={{ base: 0, md: 50 }}
          pt={8}
        >
          {/* <Box
            pos="relative"
            w={{ base: '100%', md: 'auto' }}
            h={{ base: isIOS ? '90%' : '', md: '667px' }}
          >
            <Text
              textAlign="center"
              fontSize="1.2rem"
              pos={{ base: 'relative', md: 'absolute' }}
              top={{ base: '0', md: '-2rem' }}
              left={{ base: 0, md: '50%' }}
              ms={{ base: 0, md: '-4.3rem' }}
              fontWeight="bold"
              mt={{ base: '2rem', md: 'auto' }}
            >
              Azure Open AI
            </Text>
            <Image
              src="./iphone.png"
              w="auto"
              h="full"
              display={{ base: 'none', md: 'block' }}
            />
            <Box
              pos={{ base: 'initial', md: 'absolute' }}
              top="60px"
              left="15px"
              w={{ base: 'full', md: '295px' }}
              overflow="hidden"
              className="rounded-b-3xl h-[88vh] md:h-[585px]"
            >
              {conversationId && (
                <AzureOpenAI
                  conversationId={conversationId}
                  openVoiceChat={onAzureOpenAiVoiceChatOpen}
                />
              )}
            </Box>
            {isAzureOpenAiVoiceChatOpen && (
              <Box
                h="full"
                w="full"
                pos="absolute"
                top={{ base: '3rem', md: 0 }}
              >
                {conversationId && (
                  <VoiceChatWindow
                    conversationId={conversationId}
                    onClose={onAzureOpenAiVoiceChatClose}
                  />
                )}
              </Box>
            )}
          </Box> */}
          <Box
            pos="relative"
            w={{ base: '100%', md: 'auto' }}
            h={{ base: isIOS ? '90%' : '', md: '667px' }}
          >
            <Text
              textAlign="center"
              fontSize="1.2rem"
              pos={{ base: 'relative', md: 'absolute' }}
              top={{ base: '0', md: '-2rem' }}
              left={{ base: 0, md: '50%' }}
              ms={{ base: 0, md: '-2.3rem' }}
              fontWeight="bold"
              mt={{ base: '2rem', md: 'auto' }}
            >
              Open AI
            </Text>
            <Image
              src="./iphone.png"
              w="auto"
              h="full"
              display={{ base: 'none', md: 'block' }}
            />
            <Box
              pos={{ base: 'initial', md: 'absolute' }}
              top="60px"
              left="15px"
              w={{ base: 'full', md: '295px' }}
              overflow="hidden"
              className="rounded-b-3xl h-[88vh] md:h-[585px]"
            >
              {conversationId && (
                <OpenAI
                  conversationId={conversationId}
                  openVoiceChat={onOpenAiVoiceVoiceChatOpen}
                />
              )}
            </Box>
            {isOpenAiVoiceChatOpen && (
              <Box
                h="full"
                w="full"
                pos="absolute"
                top={{ base: '3rem', md: 0 }}
              >
                {conversationId && (
                  <VoiceChatWindow
                    conversationId={conversationId}
                    onClose={onOpenAiVoiceVoiceChatClose}
                  />
                )}
              </Box>
            )}
          </Box>
        </Flex>
      </Center>
      <Box
        h={{ base: '8vh', md: '5vh' }}
        display="flex"
        alignItems="center"
        pos="relative"
        zIndex={0}
      >
        <Box display="flex" pos="absolute" w="full" justifyContent="center">
          <Text fontSize="xs" me="0.4rem">
            Powered by
          </Text>
          <Center>
            <Image
              w="100px"
              src="https://udcbep1devassests.azureedge.net/icons/techLogo.svg"
            />
          </Center>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
